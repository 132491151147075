.App {
  text-align: center;
  background-color: #ddd7d7;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* custom below! */

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.roboto-condensed-font {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color: #1e1e1e;
  text-shadow:
          -1px -8px 0 #ff1466,
          -4px -8px 0 #ff1453,
          -5px -8px 0 #ff1466;
  position: relative;
}

.header_image {
  width: 200px; /* Adjust the size as needed */
  height: auto;
  margin-bottom: 20px;
}

.towards-oblivion-title {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 4rem;
  color: #1e1e1e;
  text-shadow:
          -1px -8px 0 #ff1466,
          -4px -8px 0 #ff1453,
          -5px -8px 0 #ff1466;
  position: relative;
}

.dark_text {
  color: #2c2626;
}


